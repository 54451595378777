import * as React from "react";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";

import Meta from "../../../components/meta";
import Layout from "../../../components/layout/Layout";
import HeroBanner from "../../../components/pages/solutions/HeroBanner";
import HomeExamples from "../../../components/pages/home/HomeExamples";
import HomeFeatured from "../../../components/pages/home/HomeFeatured";
import SectionLeftOverGreyBackground from "../../../components/pages/solutions/SectionLeftOverGreyBackground";
import SectionWithRightImage from "../../../components/pages/solutions/SectionWithRightImage";
import SectionWithLeftImage from "../../../components/pages/solutions/SectionWithLeftImage";
import SectionWithVerticalImage from "../../../components/pages/solutions/SectionWithVerticalImage";
import Shoutout from "../../../components/pages/signup/Shoutout";
import Content from "../../../components/layout/Content/Content";

const ContentExtended = Styled(Content)`
  margin-top: 6rem;
`;

const Home = ({ data }) => {
  return (
    <Layout>
      <Meta
        title="Uizard For Startup Founders and Entrepreneurs | Uizard"
        description="The best design tool for startup founders and entrepreneurs to create software interfaces."
        url="/solutions/startup-founders/"
      />

      <HeroBanner
        tagline="Startup Founders"
        headline="Uizard for startup founders and entrepreneurs"
        description="Bring your MVP to life with wireframes and interactive prototypes. Create your mobile app, SaaS web app, desktop app, or website design in minutes - no design experience required."
        image={data.foundersCoverBig.childImageSharp}
        imageSmall={data.foundersCoverSmall.childImageSharp}
        imageAlt="A mobile app and a website designed in Uizard"
      />

      <SectionWithVerticalImage
        headline="Rapidly visualize"
        description="Turn your ideas into testable <a:https://uizard.io/mockups/>UX/UI mockups</a> in minutes, not months. Get started in seconds with Uizard's templates, no tutorial needed!"
        imageA={data.foundersRapidlyVisualizeBig.childImageSharp}
        imageB={data.foundersRapidlyVisualizeSmall.childImageSharp}
        imageAlt="Multiple steps of a screen design in Uizard"
      />

      <SectionLeftOverGreyBackground
        headline="Import and transform your wireframes"
        description="<a:https://uizard.io/design-assistant/>AI app design</a>; the easiest way to design digital products. With Uizard, you can even transform your hand-drawn scribbles into digital designs automatically!"
        imageA={data.foundersDrawingToWireframeBig.childImageSharp}
        imageB={data.foundersDrawingToWireframeSmall.childImageSharp}
        imageAlt="A rough hand-drawn sketch transformed into a screen design in Uizard"
        link="/design-assistant/"
      />

      <SectionWithRightImage
        tagline="Real-time collaboration"
        headline="Get feedback instantly"
        description="Just share a link to your project to start collaborating with your team or pitch investors. All from your web browser. No software install required, just pure productivity."
        imageA={data.foundersRealTimeCollaborationBig.childImageSharp}
        imageB={data.foundersRealTimeCollaborationSmall.childImageSharp}
        imageAlt="Visual showing an overview of Uizard's collaborative features"
      />

      <SectionWithLeftImage
        headline="Drive toward success"
        description="Build your wireframe, mockup, or functional prototype in minutes. Rapidly improve and adapt based on stakeholder feedback. Ideate and create alone, or as part of team. Bringing your digital vision to life has never been easier."
        imageA={data.foundersDriveTowardSuccessBig.childImageSharp}
        imageB={data.foundersDriveTowardSuccessSmall.childImageSharp}
        imageAlt="A visual showcasing multiple users designing an interactive prototype in Uizard."
      />

      <HomeFeatured />
      <HomeExamples />

      <ContentExtended>
        <Shoutout
          headline="Build your MVP"
          description="Bring your idea to life today"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </ContentExtended>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    foundersCoverBig: file(
      relativePath: { eq: "solutions/UizardFor_Founders2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1350, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersCoverSmall: file(
      relativePath: { eq: "solutions/UizardFor_Founders1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRapidlyVisualizeBig: file(
      relativePath: { eq: "founders/Founders_RapidlyVisualize_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2140, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRapidlyVisualizeSmall: file(
      relativePath: { eq: "founders/Founders_RapidlyVisualize_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1070, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDrawingToWireframeBig: file(
      relativePath: { eq: "founders/Founders_DrawingToWireframe_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 860, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDrawingToWireframeSmall: file(
      relativePath: { eq: "founders/Founders_DrawingToWireframe_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 430, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRealTimeCollaborationBig: file(
      relativePath: { eq: "prototyping/Proto_Collaborate2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1152, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersRealTimeCollaborationSmall: file(
      relativePath: { eq: "prototyping/Proto_Collaborate1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessBig: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1116, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    foundersDriveTowardSuccessSmall: file(
      relativePath: { eq: "founders/Founders_DriveTowardSuccess_1x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 559, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
